require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import '../stylesheets/devise';
import 'bootstrap/dist/js/bootstrap';

$(document).on('turbolinks:load', function() {

    if ($('.sign-up-form').length) {

        let academic_level = $('.academic-level-select').children('option:selected').text();
        toggleRegistrationFields(academic_level);

        $('.academic-level-select').on('change', function (event) {

            let academic_level = $(this).children('option:selected').text();
            toggleRegistrationFields(academic_level);
        });

        function toggleRegistrationFields(academic_level)
        {
            if(academic_level === 'Preparatoria') {
                $('.institution-professional-select').hide();
                $('.institution-professional-select').prop('disabled', true);

                $('.school-select option:contains("Preparatoria")').prop('selected', true);
                $('.school-select').prop('readonly', true);

                $('.institution-high-school-select').show();
                $('.institution-high-school-select').prop('disabled', false);
            }
            else {
                $('.institution-high-school-select').hide();
                $('.institution-high-school-select').prop('disabled', true);

                $('.institution-professional-select').show();
                $('.institution-professional-select').prop('disabled', false);
            }
        }
    }
});
